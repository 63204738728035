import Seo from '@components/global/Seo';
import Customers from '@components/pages/referral-program/refer-a-friend/Customers';
import Earnings from '@components/pages/referral-program/refer-a-friend/Earnings';
import Features from '@components/pages/referral-program/refer-a-friend/Features';
import Hero from '@components/pages/referral-program/refer-a-friend/Hero';
import MoreFeatures from '@components/pages/referral-program/refer-a-friend/MoreFeatures ';
import Pricing from '@components/pages/referral-program/refer-a-friend/Pricing';

import getGlobalProps from '@helpers/getGlobalProps';
import { PropsWithSeoData } from '@helpers/seo';

import useTranslation from '@hooks/useTranslation';
import type { GetStaticPropsContext } from 'next';

import styles from './Main.module.css';

export default function ReferAFriend({ seo }: PropsWithSeoData) {
    const { t } = useTranslation('pages/referral-program/refer-a-friend/index');
    return (
        <>
            <Seo
                title={t('seo.title')}
                description={t('seo.description')}
                noindex={!seo.robots.index}
                nofollow={!seo.robots.follow}
                canonical={seo.canonical}
            />
            <main>
                <section>
                    <div className={styles.container}>
                        <Hero />
                        <Features />
                    </div>
                </section>
                <section>
                    <Earnings />
                </section>
                <section className="customers">
                    <Customers />
                </section>
                <section className="features">
                    <MoreFeatures />
                </section>
                <section
                    id="pricing"
                    className="pricing"
                >
                    <Pricing />
                </section>
            </main>
        </>
    );
}

export async function getStaticProps(context: GetStaticPropsContext) {
    const globalProps = await getGlobalProps(context, '/referral-program/refer-a-friend.html');

    return {
        props: {
            ...globalProps,
        },
        // revalidate: 1 * 60,
    };
}
